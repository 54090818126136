<template>
  <b-container class="m-0 w-100" fluid>
    <header-slot></header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'ce-digital-users-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        CREDIT EXPERTS
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'ce-digital-users-list-credit-expert-digital' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        CREDIT EXPERTS DIGITAL
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'ce-digital-users-list-connection' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        CONNECTION
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name"></router-view>
    </b-card>
  </b-container>
</template>

<script>
export default {};
</script>

<style scoped></style>
